import React from 'react'
import AnimIO from '../atom/animIO/animIO'

export default () => (
	<div className="HybridBrain">
		<div className="HybridBrain__head">
			<div className="Ideology HybridBrain__ideology">
				<div className="Ideology__main">
					<p className="Ideology__concept">はいぶ　りっど</p>
				</div>
				<p className="Ideology__modeL">
					<span data-ruby="Lモード">左脳・言語的</span>
				</p>
				<p className="Ideology__modeR">
					<span data-ruby="Rモード">右脳・視覚的</span>
				</p>
			</div>
		</div>
		<div className="HybridBrain__body">
			<div className="l-column">
				<div className="HybridBrain__brainLR">
					<div className="HybridBrain__leftBrain">
						<AnimIO classAnim="fadeInLeft">
							<div>
								<div className="HybridBrain__leftBrainImg">
									<svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 132 245.7" style={{ enableBackground: 'new 0 0 132 245.7' }} xmlSpace="preserve" fill="#6683a7">
										<g>
											<g>
												<path d="M128,233c0.8-0.7,1.6-1.4,2.3-2.1c0.6-0.6,1.1-1.2,1.7-1.8v-16.1c-0.1-1-0.2-2-0.2-3.1v-41.8h0.2v-7.6h-0.2
			v-32.8c0.1,0,0.1-0.1,0.2-0.1v-8.2c-0.1,0-0.1,0-0.2,0.1h0V35.7c0-1,0.1-2.1,0.2-3.1V20.2c-1.5-3.6-3.6-6.8-6.1-9.7c0,0,0,0,0,0h0
			c0,0,0,0,0,0c-3.2-3.2-7.1-5.8-11.3-7.6C110.4,1,105.8,0.1,101,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0h0h0h0h0h0c-0.2,0-0.2,0-0.2,0
			c-4.8,0-9.4,1-13.6,2.8c-4.3,1.8-8.1,4.4-11.3,7.6c-4.3,4.3-7.5,9.7-9.2,15.7c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.3,0-0.4,0v0
			c-0.1,0-0.1,0-0.1,0c-4.8,0-9.7,1-14.2,3c-4.6,2-8.8,5-12.3,8.9c-3,3.3-5.2,7.1-6.7,11c-1.5,3.9-2.2,8-2.2,12.1v0v0
			c0,3.9,0.7,7.8,1.9,11.5c-5.8,2-10.8,5.4-14.7,9.8v0c-5.3,6.1-8.5,14-8.5,22.6c0,1.4,0.1,2.8,0.3,4.3c0.7,5.8,2.9,11.1,6.1,15.6
			c-3.3,2.6-6.2,5.7-8.4,9.3C2,139.7,0,146.2,0,153.1c0,4.9,1,9.6,2.8,13.9c1.8,4.3,4.4,8.1,7.7,11.3h0c3.2,3.2,7,5.8,11.3,7.6
			c1.8,0.7,3.6,1.3,5.5,1.8c-0.7,2.8-1.1,5.7-1.1,8.6v0c0,0,0,0,0,0.1h0c0,4.9,1,9.7,3,14.3c2,4.6,5,8.8,9,12.3
			c3.4,3,7.2,5.3,11.2,6.8c4,1.5,8.1,2.2,12.3,2.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c3.3,0,6.5-0.5,9.7-1.4
			c1.1,1.6,2.4,3.1,3.8,4.5v0c3.2,3.2,7.1,5.8,11.3,7.7c4.2,1.8,8.9,2.8,13.7,2.8c0,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c4.8,0,9.4-1,13.6-2.8c4.3-1.8,8.1-4.4,11.3-7.6C126.6,234.6,127.3,233.8,128,233
			C128,233,128,233,128,233z M124.2,210c0,3.1-0.6,6.2-1.8,9c0,0,0,0,0,0.1c-0.9,2.1-2.1,4.1-3.5,5.9c-0.1,0.1-0.2,0.2-0.3,0.3
			c-0.1,0.2-0.3,0.3-0.4,0.5c-0.3,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.5,0.4-0.7,0.5c-0.1,0-0.1,0.1-0.2,0.1c-1.5,1.1-3.2,2.1-4.9,2.8c-0.7,0.3-1.4,0.6-2.1,0.8c0,0,0,0,0,0
			c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.5,0.1-0.8,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.5,0.1-0.7,0.1
			c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0-0.5,0c-0.3,0-0.7,0-1,0
			c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0-1,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.7-0.1c-0.2,0-0.4,0-0.5-0.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.6-0.1-0.8-0.2
			c-0.1,0-0.1,0-0.2-0.1c-0.3-0.1-0.6-0.2-1-0.3c0,0,0,0,0,0c-0.7-0.2-1.4-0.5-2.1-0.8c-1.8-0.7-3.4-1.7-4.9-2.8
			c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.4-0.7-0.7
			c0,0-0.1-0.1-0.1-0.1v0c0,0,0,0,0,0c-0.4-0.4-0.8-0.8-1.2-1.3c-0.1-0.1-0.1-0.1-0.2-0.2c-1.2-1.4-2.2-3-3-4.6c0,0,0-0.1-0.1-0.1
			c-1.6-3.2-2.5-6.8-2.5-10.5c0-3.4,0.9-7.2,2.6-10.3c1.9-3.6,4.6-6.3,7.8-7.9c2.5-1.3,5.4-1.9,8.6-1.9c2.1,0,3.8-1.7,3.8-3.8
			s-1.7-3.8-3.8-3.8c-3.3,0-6.4,0.5-9.3,1.6c-2.7,1-5.2,2.4-7.3,4.2c-3.8,3.2-6.7,7.6-8.4,12.7c-1,3-1.5,6.2-1.5,9.2
			c0,2.8,0.4,5.5,1.1,8.2c-0.4,0.2-0.8,0.3-1.2,0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.3,0.1-0.5,0.1
			c-0.2,0.1-0.5,0.1-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.1
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8,0-1.2,0c-5.4,0-10.6-1.9-14.8-5.2
			c-0.3-0.2-0.6-0.5-0.8-0.7c-0.3-0.3-0.6-0.6-0.9-0.9c-4.4-4.4-7-10.4-7-16.7c0-2.5,0.4-4.9,1.1-7.3v0c0.1-0.3,0.2-0.5,0.3-0.8
			c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0,0c0.2-0.4,0.3-0.8,0.5-1.2c0,0,0-0.1,0-0.1c0.2-0.4,0.4-0.8,0.6-1.2c0,0,0,0,0,0
			c0.9-1.7,2-3.3,3.3-4.7c1.4-1.6,1.2-4-0.3-5.4c-0.7-0.6-1.6-1-2.5-1c-1.1,0-2.1,0.5-2.8,1.3c-0.2,0.2-0.4,0.5-0.6,0.7
			c-0.3,0.1-0.6,0.1-0.7,0.1c-0.2,0-0.3,0-0.6,0.1c-0.2,0-0.5,0-0.9,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.4,0-0.8,0-1.2,0
			c0,0-0.1,0-0.1,0c-0.4,0-0.7,0-1.1-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7-0.1-1-0.1c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.7-0.1-1-0.2
			c0,0-0.1,0-0.1,0c-0.3-0.1-0.7-0.2-1-0.2c0,0,0,0,0,0c-1.1-0.3-2.2-0.7-3.2-1.1c-1.4-0.6-2.7-1.3-4-2.2c0,0,0,0,0,0
			c-0.3-0.2-0.6-0.4-0.9-0.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3
			c-0.2-0.2-0.5-0.4-0.7-0.7c0,0-0.1-0.1-0.1-0.1l0,0c-0.3-0.3-0.5-0.6-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.3-0.4-0.5-0.5
			c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.1-0.1-0.1-0.2-0.2c-1.1-1.5-2.1-3.2-2.8-4.9c-0.4-1-0.8-2.1-1.1-3.2c0,0,0-0.1,0-0.1
			c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.1,0-0.1-0.1-0.2c-0.1-0.3-0.1-0.6-0.2-0.8c0-0.1,0-0.2-0.1-0.3c0-0.3-0.1-0.5-0.1-0.8
			c0-0.1,0-0.3,0-0.4c0-0.3,0-0.5-0.1-0.8c0-0.1,0-0.3,0-0.4c0-0.4,0-0.8,0-1.2c0-0.6,0-1.1,0.1-1.7c0-0.4,0.1-0.7,0.1-1.1
			c0-0.2,0-0.3,0.1-0.5c0.1-0.5,0.2-1,0.2-1.4c0-0.1,0-0.1,0-0.2c0.1-0.5,0.2-1,0.4-1.5c0,0,0-0.1,0-0.1c0.1-0.5,0.3-1,0.5-1.4
			c0,0,0-0.1,0-0.1c0.2-0.4,0.3-0.9,0.5-1.3c0-0.1,0.1-0.1,0.1-0.2c0.2-0.4,0.4-0.8,0.6-1.2c0-0.1,0.1-0.2,0.1-0.3
			c0.3-0.5,0.5-0.9,0.8-1.4c1.7-2.7,3.8-5,6.4-6.8c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.1c0.8-0.5,1.6-1.1,2.5-1.5l0,0v0l0,0
			c0,0,0,0,0.1,0c0.9-0.4,1.8-0.9,2.8-1.2c0.1,0,0.2,0,0.3,0c0.9,0,1.7-0.3,2.3-0.8c1.7-0.4,3.5-0.6,5.3-0.6
			c5.4,0,10.5,1.8,14.8,5.2c0.7,0.5,1.5,0.8,2.4,0.8c1.2,0,2.2-0.5,3-1.4c1.3-1.6,1.1-4-0.6-5.3c-5.5-4.4-12.4-6.9-19.5-6.9
			c-2.5,0-4.9,0.3-7.3,0.9c-0.6-0.4-1.1-0.9-1.6-1.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.7c0,0-0.1-0.1-0.1-0.1
			c-2.8-2.9-4.8-6.5-5.7-10.5c0,0,0,0,0,0c-0.1-0.3-0.1-0.6-0.2-1c0,0,0-0.1,0-0.2c-0.1-0.3-0.1-0.7-0.2-1c0,0,0,0,0-0.1
			c-0.1-0.5-0.1-0.9-0.1-1.4c0-0.5,0-0.9,0-1.4c0-0.3,0-0.7,0-1c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.4c0-0.3,0.1-0.5,0.1-0.8
			c0-0.1,0-0.1,0-0.2c0-0.3,0.1-0.6,0.1-0.8c0,0,0-0.1,0-0.1c0.1-0.3,0.1-0.6,0.2-0.9c0,0,0-0.1,0-0.1c0.1-0.3,0.1-0.6,0.2-0.8
			c0,0,0-0.1,0-0.1c0.1-0.3,0.2-0.5,0.2-0.8c0-0.1,0-0.1,0-0.2c0.1-0.2,0.2-0.5,0.3-0.7c0-0.1,0.1-0.1,0.1-0.2
			c0.1-0.2,0.2-0.4,0.3-0.7c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.5c0-0.1,0.1-0.1,0.1-0.2c0.4-0.5,0.7-0.9,1.1-1.4v0c0.1-0.2,0.3-0.3,0.5-0.5
			c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.3,0.6-0.5
			c0,0,0.1-0.1,0.1-0.1c3-2.6,6.7-4.3,10.7-5c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2,0,0.4-0.1h0h0c0,0,0,0,0.1,0c0,0,0,0,0,0
			c0.1,0,0.3,0,0.6-0.1c0,0,0.1,0,0.1,0c0.6,0,1.2-0.1,1.7-0.1c0.6,0.7,1.2,1.3,1.8,1.9c1.9,1.9,4.3,3.7,7.2,5.4
			c2.3,1.3,4.8,2.5,7.6,3.6c4.6,1.8,8.3,2.7,8.7,2.8c0.3,0.1,0.6,0.1,0.9,0.1c1.8,0,3.3-1.2,3.7-2.9c0.5-2-0.8-4.1-2.8-4.6l-0.3-0.1
			c-0.2-0.1-0.5-0.1-1-0.3c-0.8-0.2-2-0.6-3.4-1.1c-2.5-0.9-6.2-2.3-9.7-4.2c-2.3-1.3-4.2-2.7-5.6-4.1c-0.7-0.8-1.5-1.6-2.2-2.5
			c-0.1-0.1-0.2-0.2-0.3-0.3c-0.7-0.9-1.3-1.9-1.9-2.9c-0.2-0.4-0.4-0.8-0.6-1.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.4-0.4-0.8-0.6-1.3
			c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.3-0.7-0.4-1.1c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.4-1.1c0-0.2-0.1-0.3-0.1-0.5
			c-0.1-0.4-0.2-0.9-0.3-1.3c0-0.1,0-0.2-0.1-0.4c-0.1-0.4-0.1-0.8-0.2-1.1c0-0.1,0-0.2,0-0.3C41,63.9,41,63.4,41,63
			c0-0.1,0-0.2,0-0.3c0-0.5-0.1-1-0.1-1.5c0-1.1,0.1-2.1,0.2-3.1c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.5,0.1-0.7c0-0.2,0.1-0.3,0.1-0.5
			c0-0.2,0.1-0.3,0.1-0.5c0-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.4c0.1-0.2,0.1-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3
			c0.1-0.2,0.1-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6
			c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6
			c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.3-0.4,0.4-0.7c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.3-0.5,0.5-0.7c0,0,0,0,0-0.1
			c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.3,0.4-0.5,0.7-0.8c0.3-0.3,0.6-0.6,0.9-0.9c4.4-4.4,10.4-6.9,16.7-6.9c0,0,0,0,0,0
			c0.4,0,0.8,0,1.2,0c0.1,0,0.2,0,0.4,0c0.3,0,0.7,0,1,0.1c0.1,0,0.2,0,0.3,0c0.9,0.1,1.7,0.3,2.6,0.5c3.8,0.9,7.3,2.7,10.2,5.3
			c0.7,0.6,1.6,1,2.5,1c1.1,0,2.1-0.5,2.8-1.3c1.4-1.6,1.3-4-0.3-5.4c-2.3-2.1-4.9-3.8-7.7-5.1c0.2-2.1,0.7-4.1,1.4-6
			c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.2-0.5,0.3-0.8c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.4
			c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.3,0.3-0.4
			c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.3-0.4,0.5-0.6
			c0-0.1,0.1-0.1,0.1-0.2c0.2-0.3,0.4-0.5,0.7-0.7c0,0,0,0,0,0c0.2-0.3,0.5-0.5,0.7-0.7c0.3-0.3,0.6-0.5,0.8-0.8
			c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.9-0.6
			c0,0,0,0,0,0c1.3-0.8,2.6-1.6,4-2.2c0.7-0.3,1.4-0.5,2.1-0.8c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.1,0,0.2-0.1
			c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.2-0.1,0.4-0.1c0.2,0,0.5-0.1,0.7-0.1c0.2,0,0.3-0.1,0.5-0.1c0.2,0,0.4-0.1,0.6-0.1
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.3,0,0.7,0,1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.3,0,0.7,0,1,0
			c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.5,0.1,0.7,0.1
			c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.1,0.8,0.2c0.1,0,0.1,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0,0,0.1,0,0.1,0
			c0.7,0.2,1.4,0.5,2.1,0.8c1.4,0.6,2.7,1.3,4,2.2c0,0,0,0,0.1,0c0.3,0.2,0.6,0.4,0.9,0.6c0.1,0.1,0.2,0.2,0.3,0.2
			c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.1,0.2,0.2c0.3,0.3,0.6,0.5,0.8,0.8c0.1,0.1,0.1,0.1,0.2,0.2
			c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.2,0.2,0.2,0.3c1.5,1.8,2.7,3.7,3.5,5.9c0,0,0,0,0,0.1
			c1.2,2.8,1.8,5.9,1.8,9v37.6h-22c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8h22v27.4c-6.4,0.2-11.8,1-16.4,2.4
			c-5.1,1.6-9.2,3.9-12.3,7.1c-1.8,1.8-3.4,4-4.6,6.5c-1.6,3.2-2.5,6.6-3,9.1c-0.3,1.4-0.6,2.9-0.9,4.2c-0.5,2.4-0.9,4.6-1.5,6.7
			c-0.6,1.9-1.2,3.5-2,4.8c-1,1.6-2.2,2.9-3.8,3.9c-2.2,1.4-5,2.3-8.7,2.8c-1,0.1-1.9,0.6-2.5,1.4c-0.6,0.8-0.9,1.8-0.8,2.8
			c0.2,1.9,1.9,3.3,3.8,3.3c0.2,0,0.3,0,0.5,0c4.1-0.5,7.5-1.5,10.3-3.1c2.7-1.4,4.9-3.4,6.7-5.8c1-1.4,1.9-2.9,2.6-4.7
			c0.8-1.9,1.4-3.9,2-6.5c0.3-1.4,0.6-2.8,0.9-4.2c0.3-1.5,0.6-2.9,0.9-4.3c1-4.2,2.1-7.1,3.8-9.4c1.1-1.6,2.5-3,4.2-4.1
			c2.2-1.6,5-2.7,8.3-3.6c3.5-0.9,7.8-1.5,12.6-1.7V210z" />
											</g>
										</g>
									</svg>
								</div>
								<h2 className="HybridBrain__h2">Lモード</h2>
								<h3 className="HybridBrain__h3">
									【 左脳・言語的・分析的 】<br />【 ロジック脳・論理 】<br />【 テクノロジー・IT 】
								</h3>
								<p className="HybridBrain__leadcopy">
									ホームページの中身はコンピュータの領域で、ここをうまくコントロールするには、その制作者にプログラミングの素養が必要となってきます。そして、プログラミングロジックをしっかり組むには左脳を充分に活用しなければなりません。すなわち、中身のしっかりしたホームページを作るには、<b>”左脳”</b>を鍛えた人間が欠かせないということです。
								</p>
							</div>
						</AnimIO>
					</div>
					<div className="HybridBrain__centerBrain">
						<AnimIO classAnim="zoomIn">
							<div>
								<div className="HybridBrain__centerBrainImg">
									<svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 355.6 355.6" style={{ enableBackground: 'new 0 0 355.6 355.6' }} xmlSpace="preserve" fill="#6683a7">
										<g>
											<g>
												<path d="M165.1,288c0.8-0.7,1.6-1.4,2.3-2.1c0.6-0.6,1.1-1.2,1.7-1.8V268c-0.1-1-0.2-2-0.2-3.1v-41.8h0.2v-7.6h-0.2
			v-32.8c0.1,0,0.1-0.1,0.2-0.1v-8.2c-0.1,0-0.1,0-0.2,0.1l0,0V90.7c0-1,0.1-2.1,0.2-3.1V75.1c-1.5-3.6-3.6-6.8-6.1-9.7l0,0l0,0l0,0
			c-3.2-3.2-7.1-5.8-11.3-7.6C147.4,56,142.8,55,138,55c-0.1,0-0.2,0-0.3,0l0,0l0,0l0,0l0,0l0,0l0,0c-0.2,0-0.2,0-0.2,0
			c-4.8,0-9.4,1-13.6,2.8c-4.3,1.8-8.1,4.4-11.3,7.6c-4.3,4.3-7.5,9.7-9.2,15.7c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.3,0-0.4,0l0,0
			c-0.1,0-0.1,0-0.1,0c-4.8,0-9.7,1-14.2,3c-4.6,2-8.8,5-12.3,8.9c-3,3.3-5.2,7.1-6.7,11s-2.2,8-2.2,12.1l0,0l0,0
			c0,3.9,0.7,7.8,1.9,11.5c-5.8,2-10.8,5.4-14.7,9.8l0,0c-5.3,6.1-8.5,14-8.5,22.6c0,1.4,0.1,2.8,0.3,4.3c0.7,5.8,2.9,11.1,6.1,15.6
			c-3.3,2.6-6.2,5.7-8.4,9.3c-3.4,5.5-5.3,11.9-5.3,18.8c0,4.9,1,9.6,2.8,13.9c1.8,4.3,4.4,8.1,7.7,11.3l0,0c3.2,3.2,7,5.8,11.3,7.6
			c1.8,0.7,3.6,1.3,5.5,1.8c-0.7,2.8-1.1,5.7-1.1,8.6l0,0c0,0,0,0,0,0.1l0,0c0,4.9,1,9.7,3,14.3c2,4.6,5,8.8,9,12.3
			c3.4,3,7.2,5.3,11.2,6.8s8.1,2.2,12.3,2.2H99l0,0l0,0c3.3,0,6.5-0.5,9.7-1.4c1.1,1.6,2.4,3.1,3.8,4.5l0,0
			c3.2,3.2,7.1,5.8,11.3,7.7c4.2,1.8,8.9,2.8,13.7,2.8c0,0,0.1,0,0.2,0l0,0l0,0l0,0c0.1,0,0.2,0,0.3,0c4.8,0,9.4-1,13.6-2.8
			c4.3-1.8,8.1-4.4,11.3-7.6C163.7,289.5,164.4,288.7,165.1,288L165.1,288z M161.3,265c0,3.1-0.6,6.2-1.8,9c0,0,0,0,0,0.1
			c-0.9,2.1-2.1,4.1-3.5,5.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2-0.3,0.3-0.4,0.5c-0.3,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-0.8,0.8
			c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.4-0.7,0.5c-0.1,0-0.1,0.1-0.2,0.1c-1.5,1.1-3.2,2.1-4.9,2.8
			c-0.7,0.3-1.4,0.6-2.1,0.8l0,0c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.5,0.1-0.8,0.2c-0.1,0-0.2,0.1-0.3,0.1
			c-0.2,0-0.5,0.1-0.7,0.1s-0.3,0.1-0.5,0.1s-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0-0.5,0
			c-0.3,0-0.7,0-1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0-1,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.7-0.1
			c-0.2,0-0.4,0-0.5-0.1c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1s-0.5-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.3-0.1
			c-0.3-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.1,0-0.2-0.1c-0.3-0.1-0.6-0.2-1-0.3l0,0c-0.7-0.2-1.4-0.5-2.1-0.8c-1.8-0.7-3.4-1.7-4.9-2.8
			c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.4-0.7-0.7
			l-0.1-0.1l0,0l0,0c-0.4-0.4-0.8-0.8-1.2-1.3c-0.1-0.1-0.1-0.1-0.2-0.2c-1.2-1.4-2.2-3-3-4.6c0,0,0-0.1-0.1-0.1
			c-1.6-3.2-2.5-6.8-2.5-10.5c0-3.4,0.9-7.2,2.6-10.3c1.9-3.6,4.6-6.3,7.8-7.9c2.5-1.3,5.4-1.9,8.6-1.9c2.1,0,3.8-1.7,3.8-3.8
			s-1.7-3.8-3.8-3.8c-3.3,0-6.4,0.5-9.3,1.6c-2.7,1-5.2,2.4-7.3,4.2c-3.8,3.2-6.7,7.6-8.4,12.7c-1,3-1.5,6.2-1.5,9.2
			c0,2.8,0.4,5.5,1.1,8.2c-0.4,0.2-0.8,0.3-1.2,0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.3,0.1-0.5,0.1
			c-0.2,0.1-0.5,0.1-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1s-0.4,0.1-0.6,0.1s-0.4,0.1-0.6,0.1s-0.4,0-0.6,0.1
			c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8,0-1.2,0c-5.4,0-10.6-1.9-14.8-5.2c-0.3-0.2-0.6-0.5-0.8-0.7
			c-0.3-0.3-0.6-0.6-0.9-0.9c-4.4-4.4-7-10.4-7-16.7c0-2.5,0.4-4.9,1.1-7.3l0,0c0.1-0.3,0.2-0.5,0.3-0.8c0-0.1,0.1-0.2,0.1-0.2l0,0
			c0.2-0.4,0.3-0.8,0.5-1.2v-0.1c0.2-0.4,0.4-0.8,0.6-1.2l0,0c0.9-1.7,2-3.3,3.3-4.7c1.4-1.6,1.2-4-0.3-5.4c-0.7-0.6-1.6-1-2.5-1
			c-1.1,0-2.1,0.5-2.8,1.3c-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.1-0.6,0.1-0.7,0.1c-0.2,0-0.3,0-0.6,0.1c-0.2,0-0.5,0-0.9,0
			c0,0,0,0-0.1,0l0,0c-0.4,0-0.8,0-1.2,0h-0.1c-0.4,0-0.7,0-1.1-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7-0.1-1-0.1c-0.1,0-0.1,0-0.2,0
			c-0.3-0.1-0.7-0.1-1-0.2h-0.1c-0.3-0.1-0.7-0.2-1-0.2l0,0c-1.1-0.3-2.2-0.7-3.2-1.1c-1.4-0.6-2.7-1.3-4-2.2l0,0
			c-0.3-0.2-0.6-0.4-0.9-0.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3
			c-0.2-0.2-0.5-0.4-0.7-0.7l-0.1-0.1l0,0c-0.3-0.3-0.5-0.6-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.3-0.4-0.5-0.5
			c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.1-0.1-0.1-0.2-0.2c-1.1-1.5-2.1-3.2-2.8-4.9c-0.4-1-0.8-2.1-1.1-3.2v-0.1
			c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.1,0-0.1-0.1-0.2c-0.1-0.3-0.1-0.6-0.2-0.8c0-0.1,0-0.2-0.1-0.3c0-0.3-0.1-0.5-0.1-0.8
			c0-0.1,0-0.3,0-0.4c0-0.3,0-0.5-0.1-0.8c0-0.1,0-0.3,0-0.4c0-0.4,0-0.8,0-1.2c0-0.6,0-1.1,0.1-1.7c0-0.4,0.1-0.7,0.1-1.1
			c0-0.2,0-0.3,0.1-0.5c0.1-0.5,0.2-1,0.2-1.4c0-0.1,0-0.1,0-0.2c0.1-0.5,0.2-1,0.4-1.5v-0.1c0.1-0.5,0.3-1,0.5-1.4v-0.1
			c0.2-0.4,0.3-0.9,0.5-1.3c0-0.1,0.1-0.1,0.1-0.2c0.2-0.4,0.4-0.8,0.6-1.2c0-0.1,0.1-0.2,0.1-0.3c0.3-0.5,0.5-0.9,0.8-1.4
			c1.7-2.7,3.8-5,6.4-6.8l0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.1c0.8-0.5,1.6-1.1,2.5-1.5l0,0l0,0l0,0c0,0,0,0,0.1,0
			c0.9-0.4,1.8-0.9,2.8-1.2c0.1,0,0.2,0,0.3,0c0.9,0,1.7-0.3,2.3-0.8c1.7-0.4,3.5-0.6,5.3-0.6c5.4,0,10.5,1.8,14.8,5.2
			c0.7,0.5,1.5,0.8,2.4,0.8c1.2,0,2.2-0.5,3-1.4c1.3-1.6,1.1-4-0.6-5.3c-5.5-4.4-12.4-6.9-19.5-6.9c-2.5,0-4.9,0.3-7.3,0.9
			c-0.6-0.4-1.1-0.9-1.6-1.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.7l-0.1-0.1c-2.8-2.9-4.8-6.5-5.7-10.5l0,0
			c-0.1-0.3-0.1-0.6-0.2-1c0,0,0-0.1,0-0.2c-0.1-0.3-0.1-0.7-0.2-1c0,0,0,0,0-0.1c-0.1-0.5-0.1-0.9-0.1-1.4s0-0.9,0-1.4
			c0-0.3,0-0.7,0-1c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.4c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0-0.1,0-0.2c0-0.3,0.1-0.6,0.1-0.8v-0.1
			c0.1-0.3,0.1-0.6,0.2-0.9v-0.1c0.1-0.3,0.1-0.6,0.2-0.8v-0.1c0.1-0.3,0.2-0.5,0.2-0.8c0-0.1,0-0.1,0-0.2c0.1-0.2,0.2-0.5,0.3-0.7
			c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.7c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.1-0.3
			c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3
			c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.5c0-0.1,0.1-0.1,0.1-0.2c0.4-0.5,0.7-0.9,1.1-1.4l0,0
			c0.1-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3s0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2
			c0.2-0.2,0.4-0.3,0.6-0.5l0.1-0.1c3-2.6,6.7-4.3,10.7-5c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2,0,0.4-0.1l0,0l0,0c0,0,0,0,0.1,0l0,0
			c0.1,0,0.3,0,0.6-0.1h0.1c0.6,0,1.2-0.1,1.7-0.1c0.6,0.7,1.2,1.3,1.8,1.9c1.9,1.9,4.3,3.7,7.2,5.4c2.3,1.3,4.8,2.5,7.6,3.6
			c4.6,1.8,8.3,2.7,8.7,2.8c0.3,0.1,0.6,0.1,0.9,0.1c1.8,0,3.3-1.2,3.7-2.9c0.5-2-0.8-4.1-2.8-4.6l-0.3-0.1c-0.2-0.1-0.5-0.1-1-0.3
			c-0.8-0.2-2-0.6-3.4-1.1c-2.5-0.9-6.2-2.3-9.7-4.2c-2.3-1.3-4.2-2.7-5.6-4.1c-0.7-0.8-1.5-1.6-2.2-2.5c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.7-0.9-1.3-1.9-1.9-2.9c-0.2-0.4-0.4-0.8-0.6-1.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.4-0.4-0.8-0.6-1.3c-0.1-0.1-0.1-0.3-0.2-0.4
			c-0.1-0.3-0.3-0.7-0.4-1.1c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.4-1.1c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.4-0.2-0.9-0.3-1.3
			c0-0.1,0-0.2-0.1-0.4c-0.1-0.4-0.1-0.8-0.2-1.1c0-0.1,0-0.2,0-0.3c-0.1-0.5-0.1-0.9-0.1-1.4c0-0.1,0-0.2,0-0.3
			c0-0.5-0.1-1-0.1-1.5c0-1.1,0.1-2.1,0.2-3.1c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.5,0.1-0.7s0.1-0.3,0.1-0.5s0.1-0.3,0.1-0.5
			s0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.4c0.1-0.2,0.1-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.7
			c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.3,0.2-0.4
			c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.1,0.1-0.2
			c0.1-0.2,0.3-0.4,0.4-0.7l0.1-0.1c0.2-0.2,0.3-0.5,0.5-0.7c0,0,0,0,0-0.1c0.2-0.3,0.4-0.5,0.6-0.8s0.4-0.5,0.7-0.8
			c0.3-0.3,0.6-0.6,0.9-0.9c4.4-4.4,10.4-6.9,16.7-6.9l0,0c0.4,0,0.8,0,1.2,0c0.1,0,0.2,0,0.4,0c0.3,0,0.7,0,1,0.1
			c0.1,0,0.2,0,0.3,0c0.9,0.1,1.7,0.3,2.6,0.5c3.8,0.9,7.3,2.7,10.2,5.3c0.7,0.6,1.6,1,2.5,1c1.1,0,2.1-0.5,2.8-1.3
			c1.4-1.6,1.3-4-0.3-5.4c-2.3-2.1-4.9-3.8-7.7-5.1c0.2-2.1,0.7-4.1,1.4-6c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.2-0.5,0.3-0.8
			c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.2-0.3,0.2-0.4
			c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.2,0.2-0.3
			c0.1-0.2,0.3-0.4,0.4-0.5s0.1-0.2,0.2-0.3c0.2-0.2,0.3-0.4,0.5-0.6c0-0.1,0.1-0.1,0.1-0.2c0.2-0.3,0.4-0.5,0.7-0.7l0,0
			c0.2-0.3,0.5-0.5,0.7-0.7c0.3-0.3,0.6-0.5,0.8-0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5s0.4-0.3,0.6-0.5
			c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.9-0.6l0,0c1.3-0.8,2.6-1.6,4-2.2c0.7-0.3,1.4-0.5,2.1-0.8h0.1
			c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.1,0,0.2-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.2-0.1,0.4-0.1s0.5-0.1,0.7-0.1s0.3-0.1,0.5-0.1
			s0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.3,0,0.7,0,1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
			c0.3,0,0.7,0,1,0c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1
			s0.5,0.1,0.7,0.1c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.1,0.8,0.2c0.1,0,0.1,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.3h0.1
			c0.7,0.2,1.4,0.5,2.1,0.8c1.4,0.6,2.7,1.3,4,2.2c0,0,0,0,0.1,0c0.3,0.2,0.6,0.4,0.9,0.6c0.1,0.1,0.2,0.2,0.3,0.2
			c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.1,0.2,0.2c0.3,0.3,0.6,0.5,0.8,0.8c0.1,0.1,0.1,0.1,0.2,0.2
			c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.2,0.2,0.2,0.3c1.5,1.8,2.7,3.7,3.5,5.9c0,0,0,0,0,0.1
			c1.2,2.8,1.8,5.9,1.8,9v37.6h-22c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h22v27.4c-6.4,0.2-11.8,1-16.4,2.4
			c-5.1,1.6-9.2,3.9-12.3,7.1c-1.8,1.8-3.4,4-4.6,6.5c-1.6,3.2-2.5,6.6-3,9.1c-0.3,1.4-0.6,2.9-0.9,4.2c-0.5,2.4-0.9,4.6-1.5,6.7
			c-0.6,1.9-1.2,3.5-2,4.8c-1,1.6-2.2,2.9-3.8,3.9c-2.2,1.4-5,2.3-8.7,2.8c-1,0.1-1.9,0.6-2.5,1.4c-0.6,0.8-0.9,1.8-0.8,2.8
			c0.2,1.9,1.9,3.3,3.8,3.3c0.2,0,0.3,0,0.5,0c4.1-0.5,7.5-1.5,10.3-3.1c2.7-1.4,4.9-3.4,6.7-5.8c1-1.4,1.9-2.9,2.6-4.7
			c0.8-1.9,1.4-3.9,2-6.5c0.3-1.4,0.6-2.8,0.9-4.2c0.3-1.5,0.6-2.9,0.9-4.3c1-4.2,2.1-7.1,3.8-9.4c1.1-1.6,2.5-3,4.2-4.1
			c2.2-1.6,5-2.7,8.3-3.6c3.5-0.9,7.8-1.5,12.6-1.7L161.3,265L161.3,265z" />
												<path d="M318.6,208c0-6.9-2-13.3-5.4-18.8c-2.4-3.9-5.6-7.3-9.4-10.1c1-1.5,1.9-3.1,2.6-4.8c1.8-4.1,2.9-8.6,3.1-13.3
			c0-0.4,0-0.9,0-1.3c0-4.8-0.9-9.4-2.7-13.6c-1.7-4.2-4.2-8-7.3-11.2l0,0c-3.1-3.3-6.9-5.9-11.1-7.8c1-3.3,1.6-6.8,1.6-10.3l0,0
			c0-0.1,0-0.2,0-0.2c0-4.2-0.7-8.5-2.3-12.5c-1.5-4-3.8-7.9-6.8-11.3c-3.5-3.9-7.7-6.9-12.3-8.9c-4.5-2-9.3-3-14.1-3
			c-0.2,0-0.3,0-0.3,0c-0.1,0-0.1,0-0.1,0l0,0c-0.6,0-1.3,0-1.9,0.1c-1.7-6-4.9-11.4-9.2-15.7c-3.2-3.2-7.1-5.8-11.3-7.6
			C227.6,56,223,55,218.2,55c-0.1,0-0.2,0-0.3,0l0,0l0,0l0,0l0,0l0,0l0,0c-0.1,0-0.2,0-0.2,0c-4.8,0-9.4,1-13.6,2.8
			c-4.3,1.8-8.1,4.4-11.3,7.6c-0.1,0.1-0.2,0.2-0.3,0.3c-2.4,2.8-4.4,5.9-5.8,9.4v12.5c0.1,1,0.2,2,0.2,3.1v37.6h-0.2v7.6h0.2v27.4
			c-0.1,0-0.1,0-0.2,0v7.6c0.1,0,0.1,0,0.2,0V265c0,1-0.1,2.1-0.2,3.1v16.1c0.5,0.6,1.1,1.2,1.6,1.8c0.7,0.7,1.5,1.4,2.3,2.1l0,0
			c0,0,0,0,0.1-0.1c0.7,0.8,1.4,1.6,2.1,2.3c3.2,3.2,7.1,5.8,11.3,7.6c4.2,1.8,8.9,2.8,13.7,2.8c0,0,0,0,0.2,0l0,0l0,0
			c0.1,0,0.2,0,0.3,0c4.8,0,9.4-1,13.6-2.8c4.3-1.8,8.1-4.4,11.3-7.7l0,0c1.4-1.4,2.7-2.9,3.8-4.5c3.1,0.9,6.4,1.4,9.6,1.4l0,0h0.1
			l0,0l0,0l0,0c0,0,0.1,0,0.2,0c4.1,0,8.3-0.8,12.3-2.2c4-1.5,7.9-3.8,11.2-6.8c4-3.5,7-7.7,9-12.3c2-4.6,3-9.5,3-14.4l0,0l0,0v-0.1
			l0,0c0-2.9-0.4-5.7-1.1-8.6c1.9-0.5,3.7-1.1,5.5-1.8c4.3-1.8,8.1-4.4,11.3-7.6l0,0c3.2-3.2,5.8-7.1,7.7-11.3
			C317.6,217.6,318.6,212.9,318.6,208z M306.4,209.2c0,0.1,0,0.3,0,0.4c0,0.3,0,0.5-0.1,0.8c0,0.1,0,0.3,0,0.4
			c0,0.3-0.1,0.5-0.1,0.8c0,0.1,0,0.2-0.1,0.3c0,0.3-0.1,0.6-0.1,0.8c0,0.1,0,0.2-0.1,0.2c-0.1,0.3-0.1,0.6-0.2,0.9v0.1
			c-0.3,1.1-0.6,2.2-1.1,3.2c-0.6,1.4-1.3,2.7-2.2,4c0,0,0,0,0,0.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.1-0.2,0.2-0.2,0.3
			c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-0.8,0.8
			c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.4-0.7,0.5c-0.1,0.1-0.1,0.1-0.2,0.1c-1.5,1.1-3.1,2.1-4.9,2.8
			c-1.1,0.4-2.1,0.8-3.3,1.1l0,0c-0.3,0.1-0.7,0.2-1,0.2h-0.1c-0.3,0.1-0.7,0.1-1,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.7,0.1-1,0.1
			c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0.1-1.1,0.1H284c-0.4,0-0.8,0-1.2,0l0,0c0,0,0,0-0.1,0c-0.8,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.7-0.1
			c-0.2-0.3-0.4-0.5-0.7-0.8c-0.7-0.8-1.8-1.3-2.8-1.3c-0.9,0-1.8,0.3-2.5,1c-1.6,1.4-1.7,3.8-0.3,5.4c1.3,1.5,2.4,3,3.3,4.7l0,0
			c0.2,0.4,0.4,0.8,0.6,1.2c0,0,0,0.1,0.1,0.1c0.2,0.4,0.3,0.8,0.5,1.2l0,0c0.1,0.3,0.2,0.6,0.3,0.9v0.1c0,0.1,0.1,0.2,0.1,0.3
			c0.6,1.9,1,4,1,6c0,0.3,0,0.7,0,1.1c0,6.7-2.9,13.1-7.9,17.6c-4.3,3.8-9.9,6-15.6,6c-0.4,0-0.8,0-1.2,0c-0.2,0-0.3,0-0.5,0
			s-0.5,0-0.7-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.1,0-0.2-0.1-0.3-0.1
			c-0.4-0.1-0.8-0.3-1.2-0.5c0.7-2.7,1.1-5.4,1.1-8.2c0-3.9-0.8-7.8-2.4-11.5c-1.7-4.1-4.4-7.7-7.6-10.4c-0.7-0.6-1.5-0.9-2.4-0.9
			c-1.1,0-2.2,0.5-2.9,1.4c-0.6,0.8-1,1.8-0.9,2.8s0.6,1.9,1.4,2.6c2.3,1.9,4.2,4.5,5.4,7.5c1.1,2.7,1.8,5.8,1.8,8.6
			c0,3.7-0.9,7.3-2.5,10.5c0,0,0,0.1-0.1,0.1c-0.8,1.7-1.9,3.2-3,4.6c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.4-0.7,0.8-1.1,1.2l0,0
			c-0.3,0.3-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.4-0.7,0.5c-0.1,0-0.1,0.1-0.2,0.1
			c-1.5,1.1-3.2,2.1-4.9,2.8c-0.7,0.3-1.4,0.6-2.1,0.8l0,0c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.5,0.1-0.8,0.2
			c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.5,0.1-0.7,0.1s-0.3,0.1-0.5,0.1s-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.4,0-0.6,0.1
			c-0.2,0-0.4,0-0.5,0c-0.3,0-0.7,0-1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0-1,0c-0.2,0-0.3,0-0.5,0s-0.4,0-0.7-0.1
			c-0.2,0-0.4,0-0.5-0.1c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1s-0.5-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.3-0.1
			c-0.3-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.2-1-0.3l0,0c-0.7-0.2-1.4-0.5-2.1-0.8c-1.8-0.7-3.4-1.7-4.9-2.8
			c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.4-0.7-0.7
			l-0.1-0.1l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.5-0.6-0.7l-0.1-0.1c-1.5-1.8-2.7-3.7-3.5-5.9
			c0,0,0,0,0-0.1c-1.2-2.8-1.8-5.9-1.8-9v-41.8h20.1c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8h-20.1v-32.8c2.5-1.3,5.3-2,8.1-2
			c1.8,0,3.6,0.3,5.3,0.8c4.1,1.4,8.3,2.2,12.2,2.2c7.5,0,14.3-2.6,19.2-7.5c4.7-4.6,7.3-10.8,7.3-17.2c0-5.7-2-11.1-5.9-15.7
			c-0.7-0.9-1.8-1.4-2.9-1.4c-0.9,0-1.8,0.3-2.4,0.9c-1.6,1.3-1.8,3.7-0.5,5.3c2.7,3.2,4.1,7,4.1,10.8c0,4.3-1.8,8.6-5.1,11.8
			c-3.6,3.5-8.4,5.3-13.9,5.3c-3.1,0-6.4-0.6-9.8-1.8h-0.1c-2.5-0.8-5-1.2-7.6-1.2c-2.8,0-5.5,0.5-8.1,1.4l0,0V90.7
			c0-3.1,0.6-6.2,1.8-9v-0.1c0.9-2.1,2.1-4.1,3.5-5.9c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.3,0.5-0.6,0.8-0.8
			c0.3-0.3,0.6-0.5,0.8-0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.2
			c0.3-0.2,0.6-0.4,0.9-0.6l0,0c1.3-0.8,2.6-1.6,4-2.2c0.7-0.3,1.4-0.5,2.1-0.8h0.1c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.1,0,0.2-0.1
			c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.2-0.1,0.4-0.1s0.5-0.1,0.7-0.1s0.3-0.1,0.5-0.1s0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0c0.3,0,0.7,0,1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.3,0,0.7,0,1,0c0.2,0,0.4,0,0.5,0
			c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1s0.5,0.1,0.7,0.1c0.1,0,0.2,0.1,0.4,0.1
			c0.3,0.1,0.5,0.1,0.8,0.2c0.1,0,0.2,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.3h0.1c0.7,0.2,1.4,0.5,2.1,0.8c1.4,0.6,2.7,1.3,4,2.2
			c0,0,0,0,0.1,0c0.3,0.2,0.6,0.4,0.8,0.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.3,0.6,0.5
			c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.3,0.6,0.5,0.8,0.8c0.2,0.2,0.5,0.5,0.7,0.7l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.7
			c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5s0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.2,0.4
			c0.1,0.2,0.2,0.4,0.3,0.5s0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.2,0.5,0.3,0.8
			c0,0.1,0,0.1,0.1,0.2c0.7,1.9,1.2,3.9,1.4,6c-2.8,1.3-5.4,3-7.7,5.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l1.1,1.1l-1.1-1l-0.2,0.2
			c-0.2,0.2-0.4,0.5-0.8,0.9c-0.6,0.7-1.6,1.8-2.9,3c-2.2,2.2-5.6,5.4-9.1,8c-2.4,1.8-4.7,3.1-6.6,3.9c-1.1,0.5-2.1,0.8-3,0.8
			c-1,0.1-1.9,0.6-2.6,1.4c-0.6,0.8-0.9,1.8-0.8,2.8c0.2,1.9,1.8,3.4,3.8,3.4c0.1,0,0.3,0,0.4,0c1.6-0.2,3.3-0.6,5.1-1.4
			c1.5-0.6,3.1-1.5,4.8-2.5c2.7-1.7,5.6-3.9,8.6-6.6c4.5-4,8-7.9,8.8-8.8c2.9-2.5,6.4-4.3,10.1-5.2c0.4-0.1,0.9-0.2,1.3-0.3l0,0
			c0.4-0.1,0.8-0.1,1.2-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.3,0,0.4,0c0.4,0,0.8,0,1.2,0l0,0
			c6.3,0,12.3,2.5,16.7,7c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.2,0.4,0.5,0.6,0.7l0.1,0.1
			c3.4,4.2,5.3,9.4,5.3,14.8l0,0c0,0.5,0,1-0.1,1.5c0,0.2,0,0.5-0.1,0.7c0,0.3,0,0.5-0.1,0.8c0,0.3-0.1,0.6-0.1,0.9
			c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.3-0.1,0.7-0.2,1c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.4-0.2,0.7-0.3,1.1c0,0.1-0.1,0.2-0.1,0.3
			c-0.1,0.4-0.2,0.7-0.4,1.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.3,0.7-0.4,1c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2,0.3-0.3,0.6-0.5,1
			c-0.2,0.3-0.3,0.6-0.5,0.9c-0.3,0-0.5,0-0.8,0c-1.6,0-3.2,0.1-4.8,0.4c-2.1,0.3-3.5,2.3-3.2,4.3c0.3,1.9,1.9,3.2,3.8,3.2
			c0.2,0,0.4,0,0.6,0c1.2-0.2,2.4-0.3,3.6-0.3l0,0c0.3,0,0.6,0,0.8,0c1.5,0.1,2.9,0.2,4.3,0.5l0,0c1.7,0.4,3.4,1,4.9,1.7l0,0
			c1,0.5,2,1,2.9,1.6c0.1,0,0.1,0.1,0.2,0.1c0.9,0.6,1.7,1.2,2.4,1.9c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.3,0.7,0.7,1.1,1l0,0
			c0.3,0.3,0.5,0.6,0.8,0.8l0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.3,0.4
			c0.2,0.2,0.3,0.5,0.5,0.7c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.5,0.9c0,0,0,0,0,0.1c0.2,0.3,0.4,0.6,0.5,1l0,0
			c0.3,0.7,0.7,1.3,0.9,2l0,0c0.1,0.3,0.3,0.7,0.4,1c0,0,0,0,0,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.1,0.1,0.2
			c0.1,0.3,0.2,0.6,0.2,0.8c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.5,0.2,0.7c0,0.1,0.1,0.2,0.1,0.4s0.1,0.5,0.1,0.7
			c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.5,0.1,0.7s0,0.3,0.1,0.5c0,0.2,0,0.5,0.1,0.8c0,0.1,0,0.3,0,0.4c0,0.4,0,0.8,0,1.2
			c0,0.3,0,0.6,0,0.8l0,0c0,0.1,0,0.1,0,0.2c0,0.3,0,0.7-0.1,1c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.7-0.1,1.1l0,0c-0.3,2.4-1,4.7-2.1,6.8
			l0,0c-0.2,0.3-0.3,0.7-0.5,1c0,0,0,0,0,0.1c-0.2,0.3-0.4,0.6-0.5,0.9c0,0,0,0.1-0.1,0.1c-0.2,0.3-0.4,0.6-0.6,0.9
			c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.5-0.6,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.1,0.1-0.2,0.3-0.4,0.4
			c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.3,0.3-0.5,0.5c-0.4,0.4-0.9,0.9-1.4,1.3c-2.1-0.5-4.3-0.7-6.5-0.7c-7.1,0-14,2.5-19.5,6.9
			L263,184c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0.2l-0.2,0.3c-0.2,0.2-0.5,0.5-0.8,0.9c-0.7,0.7-1.8,1.8-3.3,3c-2.6,2.1-6.6,5.1-11.4,7
			c-3.3,1.3-6.6,2-9.8,2c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8c4.1,0,8.4-0.9,12.6-2.5c3.3-1.3,6.6-3.1,9.8-5.4
			c4.6-3.3,7.7-6.5,8.6-7.5c4.2-3.3,9.2-5,14.5-5c3.3,0,6.4,0.7,9.4,1.9l0,0c0.2,0.1,0.3,0.1,0.5,0.2c0.3,0.1,0.6,0.3,0.9,0.4h0.1
			l0,0c0.9,0.4,1.7,1,2.5,1.5c0.1,0.1,0.2,0.1,0.2,0.1l0.1,0.1c2.1,1.5,4,3.4,5.5,5.5c0.3,0.4,0.6,0.9,0.9,1.3
			c0.3,0.5,0.6,0.9,0.8,1.4c0,0.1,0.1,0.2,0.1,0.2c0.2,0.4,0.4,0.8,0.6,1.2c0,0.1,0,0.1,0.1,0.2c0.2,0.4,0.4,0.9,0.5,1.3v0.1
			c0.2,0.5,0.3,1,0.5,1.5c0,0,0,0,0,0.1c0.1,0.5,0.3,1,0.4,1.5c0,0.1,0,0.1,0,0.2c0.1,0.5,0.2,1,0.2,1.4c0,0.2,0,0.3,0.1,0.5
			c0,0.4,0.1,0.7,0.1,1.1c0,0.5,0.1,1.1,0.1,1.7C306.4,208.4,306.4,208.8,306.4,209.2z" />
											</g>
											<path d="M284.2,7.2l-10.7,2.6l5.3,21.7C249.2,11.1,213.9,0,177.8,0C79.8,0,0,79.8,0,177.8h11C11,85.8,85.8,11,177.8,11
		c34.7,0,68.6,10.9,96.8,31l-25.1,4.3l1.9,10.8l43.2-7.4L284.2,7.2z" />
											<path d="M344.6,177.8c0,92-74.8,166.8-166.8,166.8c-34.7,0-68.6-10.9-96.7-31l25-4.2l-1.8-10.8l-43.2,7.3l10.4,42.6
		l10.7-2.6l-5.3-21.8c29.6,20.4,64.9,31.5,101,31.5c98,0,177.8-79.8,177.8-177.8H344.6z" />
										</g>
									</svg>
								</div>
							</div>
						</AnimIO>
					</div>
					<div className="HybridBrain__rightBrain">
						<AnimIO classAnim="fadeInRight">
							<div>
								<div className="HybridBrain__rightBrainImg">
									<svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 132 245.7" style={{ enableBackground: 'new 0 0 132 245.7' }} xmlSpace="preserve" fill="#6683a7">
										<g>
											<g>
												<path d="M132,153.1c0-6.9-2-13.3-5.4-18.8c-2.4-3.9-5.6-7.3-9.4-10.1c1-1.5,1.9-3.1,2.6-4.8c1.8-4.1,2.9-8.6,3.1-13.3
			c0-0.4,0-0.9,0-1.3c0-4.8-0.9-9.4-2.7-13.6c-1.7-4.2-4.2-8-7.3-11.2v0c-3.1-3.3-6.9-5.9-11.1-7.8c1-3.3,1.6-6.8,1.6-10.3h0
			c0-0.1,0-0.2,0-0.2c0-4.2-0.7-8.5-2.3-12.5c-1.5-4-3.8-7.9-6.8-11.3c-3.5-3.9-7.7-6.9-12.3-8.9c-4.5-2-9.3-3-14.1-3
			c-0.2,0-0.3,0-0.3,0c-0.1,0-0.1,0-0.1,0v0c-0.6,0-1.3,0-1.9,0.1c-1.7-6-4.9-11.4-9.2-15.7c-3.2-3.2-7.1-5.8-11.3-7.6
			C41.1,1,36.5,0.1,31.7,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0,0,0,0,0h0h0h0h0C31.1,0,31,0,31,0c-4.8,0-9.4,1-13.6,2.8
			c-4.3,1.8-8.1,4.4-11.3,7.6c-0.1,0.1-0.2,0.2-0.3,0.3c-2.4,2.8-4.4,5.9-5.8,9.4v12.5c0.1,1,0.2,2,0.2,3.1v37.6H0v7.6h0.2v27.4
			c-0.1,0-0.1,0-0.2,0v7.6c0.1,0,0.1,0,0.2,0V210c0,1-0.1,2.1-0.2,3.1v16.1c0.5,0.6,1.1,1.2,1.6,1.8c0.7,0.7,1.5,1.4,2.3,2.1l0,0
			c0,0,0,0,0.1-0.1c0.7,0.8,1.4,1.6,2.1,2.3c3.2,3.2,7.1,5.8,11.3,7.6c4.2,1.8,8.9,2.8,13.7,2.8c0,0,0,0,0.2,0c0,0,0,0,0,0h0
			c0.1,0,0.2,0,0.3,0c4.8,0,9.4-1,13.6-2.8c4.3-1.8,8.1-4.4,11.3-7.7v0c1.4-1.4,2.7-2.9,3.8-4.5c3.1,0.9,6.4,1.4,9.6,1.4v0h0.1
			c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0.1,0,0.2,0c4.1,0,8.3-0.8,12.3-2.2c4-1.5,7.9-3.8,11.2-6.8c4-3.5,7-7.7,9-12.3
			c2-4.6,3-9.5,3-14.4v0v0v-0.1h0c0-2.9-0.4-5.7-1.1-8.6c1.9-0.5,3.7-1.1,5.5-1.8c4.3-1.8,8.1-4.4,11.3-7.6h0
			c3.2-3.2,5.8-7.1,7.7-11.3C131,162.7,132,158,132,153.1z M119.8,154.3c0,0.1,0,0.3,0,0.4c0,0.3,0,0.5-0.1,0.8c0,0.1,0,0.3,0,0.4
			c0,0.3-0.1,0.5-0.1,0.8c0,0.1,0,0.2-0.1,0.3c0,0.3-0.1,0.6-0.1,0.8c0,0.1,0,0.2-0.1,0.2c-0.1,0.3-0.1,0.6-0.2,0.9c0,0,0,0.1,0,0.1
			c-0.3,1.1-0.6,2.2-1.1,3.2c-0.6,1.4-1.3,2.7-2.2,4c0,0,0,0,0,0.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.1-0.2,0.2-0.2,0.3
			c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-0.8,0.8
			c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.4-0.7,0.5c-0.1,0.1-0.1,0.1-0.2,0.1c-1.5,1.1-3.1,2.1-4.9,2.8
			c-1.1,0.4-2.1,0.8-3.3,1.1c0,0,0,0,0,0c-0.3,0.1-0.7,0.2-1,0.2c0,0-0.1,0-0.1,0c-0.3,0.1-0.7,0.1-1,0.2c-0.1,0-0.1,0-0.2,0
			c-0.3,0.1-0.7,0.1-1,0.1c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0.1-1.1,0.1c0,0-0.1,0-0.1,0c-0.4,0-0.8,0-1.2,0c0,0,0,0,0,0
			c0,0,0,0-0.1,0c-0.8,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.7-0.1c-0.2-0.3-0.4-0.5-0.7-0.8c-0.7-0.8-1.8-1.3-2.8-1.3
			c-0.9,0-1.8,0.3-2.5,1c-1.6,1.4-1.7,3.8-0.3,5.4c1.3,1.5,2.4,3,3.3,4.7c0,0,0,0,0,0c0.2,0.4,0.4,0.8,0.6,1.2c0,0,0,0.1,0.1,0.1
			c0.2,0.4,0.3,0.8,0.5,1.2c0,0,0,0,0,0c0.1,0.3,0.2,0.6,0.3,0.9c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.3c0.6,1.9,1,4,1,6
			c0,0.3,0,0.7,0,1.1c0,6.7-2.9,13.1-7.9,17.6c-4.3,3.8-9.9,6-15.6,6c-0.4,0-0.8,0-1.2,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.5,0-0.7-0.1
			c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.1-0.8-0.3-1.2-0.5
			c0.7-2.7,1.1-5.4,1.1-8.2c0-3.9-0.8-7.8-2.4-11.5c-1.7-4.1-4.4-7.7-7.6-10.4c-0.7-0.6-1.5-0.9-2.4-0.9c-1.1,0-2.2,0.5-2.9,1.4
			c-0.6,0.8-1,1.8-0.9,2.8c0.1,1,0.6,1.9,1.4,2.6c2.3,1.9,4.2,4.5,5.4,7.5c1.1,2.7,1.8,5.8,1.8,8.6c0,3.7-0.9,7.3-2.5,10.5
			c0,0,0,0.1-0.1,0.1c-0.8,1.7-1.9,3.2-3,4.6c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.4-0.7,0.8-1.1,1.2c0,0,0,0,0,0
			c-0.3,0.3-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.4-0.7,0.5c-0.1,0-0.1,0.1-0.2,0.1
			c-1.5,1.1-3.2,2.1-4.9,2.8c-0.7,0.3-1.4,0.6-2.1,0.8c0,0,0,0,0,0c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.1,0-0.2,0.1
			c-0.3,0.1-0.5,0.1-0.8,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1
			c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0-0.5,0c-0.3,0-0.7,0-1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
			c-0.3,0-0.7,0-1,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.7-0.1c-0.2,0-0.4,0-0.5-0.1c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1
			c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.2-1-0.3c0,0,0,0,0,0
			c-0.7-0.2-1.4-0.5-2.1-0.8c-1.8-0.7-3.4-1.7-4.9-2.8c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.1-0.3-0.3-0.5-0.4
			c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.4-0.7-0.7c0,0-0.1-0.1-0.1-0.1l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.4-0.4-0.6-0.6
			c-0.2-0.2-0.4-0.5-0.6-0.7c0,0-0.1-0.1-0.1-0.1c-1.5-1.8-2.7-3.7-3.5-5.9c0,0,0,0,0-0.1c-1.2-2.8-1.8-5.9-1.8-9v-41.8h20.1
			c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8H7.8v-32.8c2.5-1.3,5.3-2,8.1-2c1.8,0,3.6,0.3,5.3,0.8c4.1,1.4,8.3,2.2,12.2,2.2
			c7.5,0,14.3-2.6,19.2-7.5c4.7-4.6,7.3-10.8,7.3-17.2c0-5.7-2-11.1-5.9-15.7c-0.7-0.9-1.8-1.4-2.9-1.4c-0.9,0-1.8,0.3-2.4,0.9
			c-1.6,1.3-1.8,3.7-0.5,5.3c2.7,3.2,4.1,7,4.1,10.8c0,4.3-1.8,8.6-5.1,11.8c-3.6,3.5-8.4,5.3-13.9,5.3c-3.1,0-6.4-0.6-9.8-1.8
			l-0.1,0c-2.5-0.8-5-1.2-7.6-1.2c-2.8,0-5.5,0.5-8.1,1.4h0V35.7c0-3.1,0.6-6.2,1.8-9c0,0,0-0.1,0-0.1c0.9-2.1,2.1-4.1,3.5-5.9
			c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.3,0.5-0.6,0.8-0.8c0.3-0.3,0.6-0.5,0.8-0.8c0.1-0.1,0.2-0.2,0.3-0.2
			c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.9-0.6c0,0,0,0,0,0
			c1.3-0.8,2.6-1.6,4-2.2c0.7-0.3,1.4-0.5,2.1-0.8c0,0,0.1,0,0.1,0c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.1,0,0.2-0.1
			c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.2-0.1,0.4-0.1c0.2,0,0.5-0.1,0.7-0.1c0.2,0,0.3-0.1,0.5-0.1c0.2,0,0.4-0.1,0.6-0.1
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0c0.3,0,0.7,0,1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.3,0,0.7,0,1,0
			c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.5,0.1,0.7,0.1
			c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.1,0.8,0.2c0.1,0,0.2,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0,0,0.1,0,0.1,0
			c0.7,0.2,1.4,0.5,2.1,0.8c1.4,0.6,2.7,1.3,4,2.2c0,0,0,0,0.1,0c0.3,0.2,0.6,0.4,0.8,0.6c0.1,0.1,0.2,0.2,0.3,0.2
			c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.3,0.6,0.5,0.8,0.8c0.2,0.2,0.5,0.5,0.7,0.7
			c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.7c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.1,0.2,0.2,0.3
			c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.6
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.2,0.5,0.3,0.8c0,0.1,0,0.1,0.1,0.2c0.7,1.9,1.2,3.9,1.4,6c-2.8,1.3-5.4,3-7.7,5.1l-0.1,0.1
			l-0.1,0.1l-0.1,0.1l1.1,1.1l-1.1-1l-0.2,0.2c-0.2,0.2-0.4,0.5-0.8,0.9c-0.6,0.7-1.6,1.8-2.9,3c-2.2,2.2-5.6,5.4-9.1,8
			c-2.4,1.8-4.7,3.1-6.6,3.9c-1.1,0.5-2.1,0.8-3,0.8c-1,0.1-1.9,0.6-2.6,1.4C20.9,58,20.6,59,20.7,60c0.2,1.9,1.8,3.4,3.8,3.4
			c0.1,0,0.3,0,0.4,0c1.6-0.2,3.3-0.6,5.1-1.4c1.5-0.6,3.1-1.5,4.8-2.5c2.7-1.7,5.6-3.9,8.6-6.6c4.5-4,8-7.9,8.8-8.8
			c2.9-2.5,6.4-4.3,10.1-5.2c0.4-0.1,0.9-0.2,1.3-0.3c0,0,0,0,0,0c0.4-0.1,0.8-0.1,1.2-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.9-0.1
			c0.1,0,0.3,0,0.4,0c0.4,0,0.8,0,1.2,0c0,0,0,0,0,0c6.3,0,12.3,2.5,16.7,7c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.3,0.3,0.4,0.4
			c0.2,0.2,0.4,0.5,0.6,0.7c0,0,0.1,0.1,0.1,0.1c3.4,4.2,5.3,9.4,5.3,14.8v0c0,0.5,0,1-0.1,1.5c0,0.2,0,0.5-0.1,0.7
			c0,0.3,0,0.5-0.1,0.8c0,0.3-0.1,0.6-0.1,0.9c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.3-0.1,0.7-0.2,1c0,0.1-0.1,0.3-0.1,0.4
			c-0.1,0.4-0.2,0.7-0.3,1.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.4-0.2,0.7-0.4,1.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.3,0.7-0.4,1
			c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2,0.3-0.3,0.6-0.5,1c-0.2,0.3-0.3,0.6-0.5,0.9c-0.3,0-0.5,0-0.8,0c-1.6,0-3.2,0.1-4.8,0.4
			c-2.1,0.3-3.5,2.3-3.2,4.3c0.3,1.9,1.9,3.2,3.8,3.2c0.2,0,0.4,0,0.6,0c1.2-0.2,2.4-0.3,3.6-0.3h0c0.3,0,0.6,0,0.8,0
			c1.5,0.1,2.9,0.2,4.3,0.5c0,0,0,0,0,0c1.7,0.4,3.4,1,4.9,1.7c0,0,0,0,0,0c1,0.5,2,1,2.9,1.6c0.1,0,0.1,0.1,0.2,0.1
			c0.9,0.6,1.7,1.2,2.4,1.9c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.3,0.7,0.7,1.1,1c0,0,0,0,0,0c0.3,0.3,0.5,0.6,0.8,0.8
			c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.3,0.5,0.5,0.7
			c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.5,0.9c0,0,0,0,0,0.1c0.2,0.3,0.4,0.6,0.5,1c0,0,0,0,0,0c0.3,0.7,0.7,1.3,0.9,2l0,0
			c0.1,0.3,0.3,0.7,0.4,1c0,0,0,0,0,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.1,0.1,0.2c0.1,0.3,0.2,0.6,0.2,0.8
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.5,0.2,0.7c0,0.1,0.1,0.2,0.1,0.4c0,0.2,0.1,0.5,0.1,0.7c0,0.1,0.1,0.3,0.1,0.4
			c0,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.3,0.1,0.5c0,0.2,0,0.5,0.1,0.8c0,0.1,0,0.3,0,0.4c0,0.4,0,0.8,0,1.2c0,0.3,0,0.6,0,0.8v0
			c0,0.1,0,0.1,0,0.2c0,0.3,0,0.7-0.1,1c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.7-0.1,1.1c0,0,0,0,0,0c-0.3,2.4-1,4.7-2.1,6.8c0,0,0,0,0,0
			c-0.2,0.3-0.3,0.7-0.5,1c0,0,0,0,0,0.1c-0.2,0.3-0.4,0.6-0.5,0.9c0,0,0,0.1-0.1,0.1c-0.2,0.3-0.4,0.6-0.6,0.9
			c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.5-0.6,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.1,0.1-0.2,0.3-0.4,0.4
			c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.3,0.3-0.5,0.5c-0.4,0.4-0.9,0.9-1.4,1.3c-2.1-0.5-4.3-0.7-6.5-0.7c-7.1,0-14,2.5-19.5,6.9
			l-0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0.2l-0.2,0.3c-0.2,0.2-0.5,0.5-0.8,0.9c-0.7,0.7-1.8,1.8-3.3,3c-2.6,2.1-6.6,5.1-11.4,7
			c-3.3,1.3-6.6,2-9.8,2c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8c4.1,0,8.4-0.9,12.6-2.5c3.3-1.3,6.6-3.1,9.8-5.4
			c4.6-3.3,7.7-6.5,8.6-7.5c4.2-3.3,9.2-5,14.5-5c3.3,0,6.4,0.7,9.4,1.9c0,0,0,0,0,0c0.2,0.1,0.3,0.1,0.5,0.2
			c0.3,0.1,0.6,0.3,0.9,0.4c0,0,0.1,0,0.1,0l0,0c0.9,0.4,1.7,1,2.5,1.5c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1
			c2.1,1.5,4,3.4,5.5,5.5c0.3,0.4,0.6,0.9,0.9,1.3c0.3,0.5,0.6,0.9,0.8,1.4c0,0.1,0.1,0.2,0.1,0.2c0.2,0.4,0.4,0.8,0.6,1.2
			c0,0.1,0,0.1,0.1,0.2c0.2,0.4,0.4,0.9,0.5,1.3c0,0,0,0.1,0,0.1c0.2,0.5,0.3,1,0.5,1.5c0,0,0,0,0,0.1c0.1,0.5,0.3,1,0.4,1.5
			c0,0.1,0,0.1,0,0.2c0.1,0.5,0.2,1,0.2,1.4c0,0.2,0,0.3,0.1,0.5c0,0.4,0.1,0.7,0.1,1.1c0,0.5,0.1,1.1,0.1,1.7
			C119.8,153.5,119.8,153.9,119.8,154.3z" />
											</g>
										</g>
									</svg>
								</div>
								<h2 className="HybridBrain__h2">Rモード</h2>
								<h3 className="HybridBrain__h3">【 右脳・視覚的・知覚的 】<br />【 イメージ脳・直感 】<br />【 グラフィックデザイン 】</h3>
								<p className="HybridBrain__leadcopy">ホームページの外見は人間が目で見る領域で、ここを見やすくキレイに仕上げるには、その制作者にグラフィックデザインの素養が必要となってきます。そして、センス良くデザインするには右脳を充分に活用しなければなりません。すなわち、見栄えの良いホームページを作るには、<b>”右脳”</b>を鍛えた人間が欠かせないということです。</p>
							</div>
						</AnimIO>
					</div>
				</div>
			</div>
		</div>
		<div className="HybridBrain__foot">
			<div className="l-column">
				<div>
					<div className="HybridBrain__centerBrainImg non-tb non-pc HybridBrain__centerBrainImg--mb15">
						<svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 355.6 355.6" style={{ enableBackground: 'new 0 0 355.6 355.6' }} xmlSpace="preserve" fill="#6683a7">
							<g>
								<g>
									<path d="M165.1,288c0.8-0.7,1.6-1.4,2.3-2.1c0.6-0.6,1.1-1.2,1.7-1.8V268c-0.1-1-0.2-2-0.2-3.1v-41.8h0.2v-7.6h-0.2
			v-32.8c0.1,0,0.1-0.1,0.2-0.1v-8.2c-0.1,0-0.1,0-0.2,0.1l0,0V90.7c0-1,0.1-2.1,0.2-3.1V75.1c-1.5-3.6-3.6-6.8-6.1-9.7l0,0l0,0l0,0
			c-3.2-3.2-7.1-5.8-11.3-7.6C147.4,56,142.8,55,138,55c-0.1,0-0.2,0-0.3,0l0,0l0,0l0,0l0,0l0,0l0,0c-0.2,0-0.2,0-0.2,0
			c-4.8,0-9.4,1-13.6,2.8c-4.3,1.8-8.1,4.4-11.3,7.6c-4.3,4.3-7.5,9.7-9.2,15.7c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.3,0-0.4,0l0,0
			c-0.1,0-0.1,0-0.1,0c-4.8,0-9.7,1-14.2,3c-4.6,2-8.8,5-12.3,8.9c-3,3.3-5.2,7.1-6.7,11s-2.2,8-2.2,12.1l0,0l0,0
			c0,3.9,0.7,7.8,1.9,11.5c-5.8,2-10.8,5.4-14.7,9.8l0,0c-5.3,6.1-8.5,14-8.5,22.6c0,1.4,0.1,2.8,0.3,4.3c0.7,5.8,2.9,11.1,6.1,15.6
			c-3.3,2.6-6.2,5.7-8.4,9.3c-3.4,5.5-5.3,11.9-5.3,18.8c0,4.9,1,9.6,2.8,13.9c1.8,4.3,4.4,8.1,7.7,11.3l0,0c3.2,3.2,7,5.8,11.3,7.6
			c1.8,0.7,3.6,1.3,5.5,1.8c-0.7,2.8-1.1,5.7-1.1,8.6l0,0c0,0,0,0,0,0.1l0,0c0,4.9,1,9.7,3,14.3c2,4.6,5,8.8,9,12.3
			c3.4,3,7.2,5.3,11.2,6.8s8.1,2.2,12.3,2.2H99l0,0l0,0c3.3,0,6.5-0.5,9.7-1.4c1.1,1.6,2.4,3.1,3.8,4.5l0,0
			c3.2,3.2,7.1,5.8,11.3,7.7c4.2,1.8,8.9,2.8,13.7,2.8c0,0,0.1,0,0.2,0l0,0l0,0l0,0c0.1,0,0.2,0,0.3,0c4.8,0,9.4-1,13.6-2.8
			c4.3-1.8,8.1-4.4,11.3-7.6C163.7,289.5,164.4,288.7,165.1,288L165.1,288z M161.3,265c0,3.1-0.6,6.2-1.8,9c0,0,0,0,0,0.1
			c-0.9,2.1-2.1,4.1-3.5,5.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2-0.3,0.3-0.4,0.5c-0.3,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-0.8,0.8
			c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.4-0.7,0.5c-0.1,0-0.1,0.1-0.2,0.1c-1.5,1.1-3.2,2.1-4.9,2.8
			c-0.7,0.3-1.4,0.6-2.1,0.8l0,0c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.5,0.1-0.8,0.2c-0.1,0-0.2,0.1-0.3,0.1
			c-0.2,0-0.5,0.1-0.7,0.1s-0.3,0.1-0.5,0.1s-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0-0.5,0
			c-0.3,0-0.7,0-1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0-1,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.7-0.1
			c-0.2,0-0.4,0-0.5-0.1c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1s-0.5-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.3-0.1
			c-0.3-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.1,0-0.2-0.1c-0.3-0.1-0.6-0.2-1-0.3l0,0c-0.7-0.2-1.4-0.5-2.1-0.8c-1.8-0.7-3.4-1.7-4.9-2.8
			c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.4-0.7-0.7
			l-0.1-0.1l0,0l0,0c-0.4-0.4-0.8-0.8-1.2-1.3c-0.1-0.1-0.1-0.1-0.2-0.2c-1.2-1.4-2.2-3-3-4.6c0,0,0-0.1-0.1-0.1
			c-1.6-3.2-2.5-6.8-2.5-10.5c0-3.4,0.9-7.2,2.6-10.3c1.9-3.6,4.6-6.3,7.8-7.9c2.5-1.3,5.4-1.9,8.6-1.9c2.1,0,3.8-1.7,3.8-3.8
			s-1.7-3.8-3.8-3.8c-3.3,0-6.4,0.5-9.3,1.6c-2.7,1-5.2,2.4-7.3,4.2c-3.8,3.2-6.7,7.6-8.4,12.7c-1,3-1.5,6.2-1.5,9.2
			c0,2.8,0.4,5.5,1.1,8.2c-0.4,0.2-0.8,0.3-1.2,0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.3,0.1-0.5,0.1
			c-0.2,0.1-0.5,0.1-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1s-0.4,0.1-0.6,0.1s-0.4,0.1-0.6,0.1s-0.4,0-0.6,0.1
			c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8,0-1.2,0c-5.4,0-10.6-1.9-14.8-5.2c-0.3-0.2-0.6-0.5-0.8-0.7
			c-0.3-0.3-0.6-0.6-0.9-0.9c-4.4-4.4-7-10.4-7-16.7c0-2.5,0.4-4.9,1.1-7.3l0,0c0.1-0.3,0.2-0.5,0.3-0.8c0-0.1,0.1-0.2,0.1-0.2l0,0
			c0.2-0.4,0.3-0.8,0.5-1.2v-0.1c0.2-0.4,0.4-0.8,0.6-1.2l0,0c0.9-1.7,2-3.3,3.3-4.7c1.4-1.6,1.2-4-0.3-5.4c-0.7-0.6-1.6-1-2.5-1
			c-1.1,0-2.1,0.5-2.8,1.3c-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.1-0.6,0.1-0.7,0.1c-0.2,0-0.3,0-0.6,0.1c-0.2,0-0.5,0-0.9,0
			c0,0,0,0-0.1,0l0,0c-0.4,0-0.8,0-1.2,0h-0.1c-0.4,0-0.7,0-1.1-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7-0.1-1-0.1c-0.1,0-0.1,0-0.2,0
			c-0.3-0.1-0.7-0.1-1-0.2h-0.1c-0.3-0.1-0.7-0.2-1-0.2l0,0c-1.1-0.3-2.2-0.7-3.2-1.1c-1.4-0.6-2.7-1.3-4-2.2l0,0
			c-0.3-0.2-0.6-0.4-0.9-0.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3
			c-0.2-0.2-0.5-0.4-0.7-0.7l-0.1-0.1l0,0c-0.3-0.3-0.5-0.6-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.3-0.4-0.5-0.5
			c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.1-0.1-0.1-0.2-0.2c-1.1-1.5-2.1-3.2-2.8-4.9c-0.4-1-0.8-2.1-1.1-3.2v-0.1
			c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.1,0-0.1-0.1-0.2c-0.1-0.3-0.1-0.6-0.2-0.8c0-0.1,0-0.2-0.1-0.3c0-0.3-0.1-0.5-0.1-0.8
			c0-0.1,0-0.3,0-0.4c0-0.3,0-0.5-0.1-0.8c0-0.1,0-0.3,0-0.4c0-0.4,0-0.8,0-1.2c0-0.6,0-1.1,0.1-1.7c0-0.4,0.1-0.7,0.1-1.1
			c0-0.2,0-0.3,0.1-0.5c0.1-0.5,0.2-1,0.2-1.4c0-0.1,0-0.1,0-0.2c0.1-0.5,0.2-1,0.4-1.5v-0.1c0.1-0.5,0.3-1,0.5-1.4v-0.1
			c0.2-0.4,0.3-0.9,0.5-1.3c0-0.1,0.1-0.1,0.1-0.2c0.2-0.4,0.4-0.8,0.6-1.2c0-0.1,0.1-0.2,0.1-0.3c0.3-0.5,0.5-0.9,0.8-1.4
			c1.7-2.7,3.8-5,6.4-6.8l0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.1c0.8-0.5,1.6-1.1,2.5-1.5l0,0l0,0l0,0c0,0,0,0,0.1,0
			c0.9-0.4,1.8-0.9,2.8-1.2c0.1,0,0.2,0,0.3,0c0.9,0,1.7-0.3,2.3-0.8c1.7-0.4,3.5-0.6,5.3-0.6c5.4,0,10.5,1.8,14.8,5.2
			c0.7,0.5,1.5,0.8,2.4,0.8c1.2,0,2.2-0.5,3-1.4c1.3-1.6,1.1-4-0.6-5.3c-5.5-4.4-12.4-6.9-19.5-6.9c-2.5,0-4.9,0.3-7.3,0.9
			c-0.6-0.4-1.1-0.9-1.6-1.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.7l-0.1-0.1c-2.8-2.9-4.8-6.5-5.7-10.5l0,0
			c-0.1-0.3-0.1-0.6-0.2-1c0,0,0-0.1,0-0.2c-0.1-0.3-0.1-0.7-0.2-1c0,0,0,0,0-0.1c-0.1-0.5-0.1-0.9-0.1-1.4s0-0.9,0-1.4
			c0-0.3,0-0.7,0-1c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.4c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0-0.1,0-0.2c0-0.3,0.1-0.6,0.1-0.8v-0.1
			c0.1-0.3,0.1-0.6,0.2-0.9v-0.1c0.1-0.3,0.1-0.6,0.2-0.8v-0.1c0.1-0.3,0.2-0.5,0.2-0.8c0-0.1,0-0.1,0-0.2c0.1-0.2,0.2-0.5,0.3-0.7
			c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.7c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.1-0.3
			c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3
			c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.5c0-0.1,0.1-0.1,0.1-0.2c0.4-0.5,0.7-0.9,1.1-1.4l0,0
			c0.1-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3s0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2
			c0.2-0.2,0.4-0.3,0.6-0.5l0.1-0.1c3-2.6,6.7-4.3,10.7-5c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2,0,0.4-0.1l0,0l0,0c0,0,0,0,0.1,0l0,0
			c0.1,0,0.3,0,0.6-0.1h0.1c0.6,0,1.2-0.1,1.7-0.1c0.6,0.7,1.2,1.3,1.8,1.9c1.9,1.9,4.3,3.7,7.2,5.4c2.3,1.3,4.8,2.5,7.6,3.6
			c4.6,1.8,8.3,2.7,8.7,2.8c0.3,0.1,0.6,0.1,0.9,0.1c1.8,0,3.3-1.2,3.7-2.9c0.5-2-0.8-4.1-2.8-4.6l-0.3-0.1c-0.2-0.1-0.5-0.1-1-0.3
			c-0.8-0.2-2-0.6-3.4-1.1c-2.5-0.9-6.2-2.3-9.7-4.2c-2.3-1.3-4.2-2.7-5.6-4.1c-0.7-0.8-1.5-1.6-2.2-2.5c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.7-0.9-1.3-1.9-1.9-2.9c-0.2-0.4-0.4-0.8-0.6-1.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.4-0.4-0.8-0.6-1.3c-0.1-0.1-0.1-0.3-0.2-0.4
			c-0.1-0.3-0.3-0.7-0.4-1.1c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.4-1.1c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.4-0.2-0.9-0.3-1.3
			c0-0.1,0-0.2-0.1-0.4c-0.1-0.4-0.1-0.8-0.2-1.1c0-0.1,0-0.2,0-0.3c-0.1-0.5-0.1-0.9-0.1-1.4c0-0.1,0-0.2,0-0.3
			c0-0.5-0.1-1-0.1-1.5c0-1.1,0.1-2.1,0.2-3.1c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.5,0.1-0.7s0.1-0.3,0.1-0.5s0.1-0.3,0.1-0.5
			s0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.4c0.1-0.2,0.1-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.7
			c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.3,0.2-0.4
			c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.1,0.1-0.2
			c0.1-0.2,0.3-0.4,0.4-0.7l0.1-0.1c0.2-0.2,0.3-0.5,0.5-0.7c0,0,0,0,0-0.1c0.2-0.3,0.4-0.5,0.6-0.8s0.4-0.5,0.7-0.8
			c0.3-0.3,0.6-0.6,0.9-0.9c4.4-4.4,10.4-6.9,16.7-6.9l0,0c0.4,0,0.8,0,1.2,0c0.1,0,0.2,0,0.4,0c0.3,0,0.7,0,1,0.1
			c0.1,0,0.2,0,0.3,0c0.9,0.1,1.7,0.3,2.6,0.5c3.8,0.9,7.3,2.7,10.2,5.3c0.7,0.6,1.6,1,2.5,1c1.1,0,2.1-0.5,2.8-1.3
			c1.4-1.6,1.3-4-0.3-5.4c-2.3-2.1-4.9-3.8-7.7-5.1c0.2-2.1,0.7-4.1,1.4-6c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.2-0.5,0.3-0.8
			c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.2-0.3,0.2-0.4
			c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.2,0.2-0.3
			c0.1-0.2,0.3-0.4,0.4-0.5s0.1-0.2,0.2-0.3c0.2-0.2,0.3-0.4,0.5-0.6c0-0.1,0.1-0.1,0.1-0.2c0.2-0.3,0.4-0.5,0.7-0.7l0,0
			c0.2-0.3,0.5-0.5,0.7-0.7c0.3-0.3,0.6-0.5,0.8-0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5s0.4-0.3,0.6-0.5
			c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.4,0.9-0.6l0,0c1.3-0.8,2.6-1.6,4-2.2c0.7-0.3,1.4-0.5,2.1-0.8h0.1
			c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.1,0,0.2-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.2-0.1,0.4-0.1s0.5-0.1,0.7-0.1s0.3-0.1,0.5-0.1
			s0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.3,0,0.7,0,1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
			c0.3,0,0.7,0,1,0c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1
			s0.5,0.1,0.7,0.1c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.1,0.8,0.2c0.1,0,0.1,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.3h0.1
			c0.7,0.2,1.4,0.5,2.1,0.8c1.4,0.6,2.7,1.3,4,2.2c0,0,0,0,0.1,0c0.3,0.2,0.6,0.4,0.9,0.6c0.1,0.1,0.2,0.2,0.3,0.2
			c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.1,0.2,0.2c0.3,0.3,0.6,0.5,0.8,0.8c0.1,0.1,0.1,0.1,0.2,0.2
			c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.2,0.2,0.2,0.3c1.5,1.8,2.7,3.7,3.5,5.9c0,0,0,0,0,0.1
			c1.2,2.8,1.8,5.9,1.8,9v37.6h-22c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h22v27.4c-6.4,0.2-11.8,1-16.4,2.4
			c-5.1,1.6-9.2,3.9-12.3,7.1c-1.8,1.8-3.4,4-4.6,6.5c-1.6,3.2-2.5,6.6-3,9.1c-0.3,1.4-0.6,2.9-0.9,4.2c-0.5,2.4-0.9,4.6-1.5,6.7
			c-0.6,1.9-1.2,3.5-2,4.8c-1,1.6-2.2,2.9-3.8,3.9c-2.2,1.4-5,2.3-8.7,2.8c-1,0.1-1.9,0.6-2.5,1.4c-0.6,0.8-0.9,1.8-0.8,2.8
			c0.2,1.9,1.9,3.3,3.8,3.3c0.2,0,0.3,0,0.5,0c4.1-0.5,7.5-1.5,10.3-3.1c2.7-1.4,4.9-3.4,6.7-5.8c1-1.4,1.9-2.9,2.6-4.7
			c0.8-1.9,1.4-3.9,2-6.5c0.3-1.4,0.6-2.8,0.9-4.2c0.3-1.5,0.6-2.9,0.9-4.3c1-4.2,2.1-7.1,3.8-9.4c1.1-1.6,2.5-3,4.2-4.1
			c2.2-1.6,5-2.7,8.3-3.6c3.5-0.9,7.8-1.5,12.6-1.7L161.3,265L161.3,265z" />
									<path d="M318.6,208c0-6.9-2-13.3-5.4-18.8c-2.4-3.9-5.6-7.3-9.4-10.1c1-1.5,1.9-3.1,2.6-4.8c1.8-4.1,2.9-8.6,3.1-13.3
			c0-0.4,0-0.9,0-1.3c0-4.8-0.9-9.4-2.7-13.6c-1.7-4.2-4.2-8-7.3-11.2l0,0c-3.1-3.3-6.9-5.9-11.1-7.8c1-3.3,1.6-6.8,1.6-10.3l0,0
			c0-0.1,0-0.2,0-0.2c0-4.2-0.7-8.5-2.3-12.5c-1.5-4-3.8-7.9-6.8-11.3c-3.5-3.9-7.7-6.9-12.3-8.9c-4.5-2-9.3-3-14.1-3
			c-0.2,0-0.3,0-0.3,0c-0.1,0-0.1,0-0.1,0l0,0c-0.6,0-1.3,0-1.9,0.1c-1.7-6-4.9-11.4-9.2-15.7c-3.2-3.2-7.1-5.8-11.3-7.6
			C227.6,56,223,55,218.2,55c-0.1,0-0.2,0-0.3,0l0,0l0,0l0,0l0,0l0,0l0,0c-0.1,0-0.2,0-0.2,0c-4.8,0-9.4,1-13.6,2.8
			c-4.3,1.8-8.1,4.4-11.3,7.6c-0.1,0.1-0.2,0.2-0.3,0.3c-2.4,2.8-4.4,5.9-5.8,9.4v12.5c0.1,1,0.2,2,0.2,3.1v37.6h-0.2v7.6h0.2v27.4
			c-0.1,0-0.1,0-0.2,0v7.6c0.1,0,0.1,0,0.2,0V265c0,1-0.1,2.1-0.2,3.1v16.1c0.5,0.6,1.1,1.2,1.6,1.8c0.7,0.7,1.5,1.4,2.3,2.1l0,0
			c0,0,0,0,0.1-0.1c0.7,0.8,1.4,1.6,2.1,2.3c3.2,3.2,7.1,5.8,11.3,7.6c4.2,1.8,8.9,2.8,13.7,2.8c0,0,0,0,0.2,0l0,0l0,0
			c0.1,0,0.2,0,0.3,0c4.8,0,9.4-1,13.6-2.8c4.3-1.8,8.1-4.4,11.3-7.7l0,0c1.4-1.4,2.7-2.9,3.8-4.5c3.1,0.9,6.4,1.4,9.6,1.4l0,0h0.1
			l0,0l0,0l0,0c0,0,0.1,0,0.2,0c4.1,0,8.3-0.8,12.3-2.2c4-1.5,7.9-3.8,11.2-6.8c4-3.5,7-7.7,9-12.3c2-4.6,3-9.5,3-14.4l0,0l0,0v-0.1
			l0,0c0-2.9-0.4-5.7-1.1-8.6c1.9-0.5,3.7-1.1,5.5-1.8c4.3-1.8,8.1-4.4,11.3-7.6l0,0c3.2-3.2,5.8-7.1,7.7-11.3
			C317.6,217.6,318.6,212.9,318.6,208z M306.4,209.2c0,0.1,0,0.3,0,0.4c0,0.3,0,0.5-0.1,0.8c0,0.1,0,0.3,0,0.4
			c0,0.3-0.1,0.5-0.1,0.8c0,0.1,0,0.2-0.1,0.3c0,0.3-0.1,0.6-0.1,0.8c0,0.1,0,0.2-0.1,0.2c-0.1,0.3-0.1,0.6-0.2,0.9v0.1
			c-0.3,1.1-0.6,2.2-1.1,3.2c-0.6,1.4-1.3,2.7-2.2,4c0,0,0,0,0,0.1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.1-0.2,0.2-0.2,0.3
			c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.5,0.6-0.8,0.8c-0.3,0.3-0.6,0.5-0.8,0.8
			c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.4-0.7,0.5c-0.1,0.1-0.1,0.1-0.2,0.1c-1.5,1.1-3.1,2.1-4.9,2.8
			c-1.1,0.4-2.1,0.8-3.3,1.1l0,0c-0.3,0.1-0.7,0.2-1,0.2h-0.1c-0.3,0.1-0.7,0.1-1,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.7,0.1-1,0.1
			c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0.1-1.1,0.1H284c-0.4,0-0.8,0-1.2,0l0,0c0,0,0,0-0.1,0c-0.8,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.7-0.1
			c-0.2-0.3-0.4-0.5-0.7-0.8c-0.7-0.8-1.8-1.3-2.8-1.3c-0.9,0-1.8,0.3-2.5,1c-1.6,1.4-1.7,3.8-0.3,5.4c1.3,1.5,2.4,3,3.3,4.7l0,0
			c0.2,0.4,0.4,0.8,0.6,1.2c0,0,0,0.1,0.1,0.1c0.2,0.4,0.3,0.8,0.5,1.2l0,0c0.1,0.3,0.2,0.6,0.3,0.9v0.1c0,0.1,0.1,0.2,0.1,0.3
			c0.6,1.9,1,4,1,6c0,0.3,0,0.7,0,1.1c0,6.7-2.9,13.1-7.9,17.6c-4.3,3.8-9.9,6-15.6,6c-0.4,0-0.8,0-1.2,0c-0.2,0-0.3,0-0.5,0
			s-0.5,0-0.7-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.1,0-0.2-0.1-0.3-0.1
			c-0.4-0.1-0.8-0.3-1.2-0.5c0.7-2.7,1.1-5.4,1.1-8.2c0-3.9-0.8-7.8-2.4-11.5c-1.7-4.1-4.4-7.7-7.6-10.4c-0.7-0.6-1.5-0.9-2.4-0.9
			c-1.1,0-2.2,0.5-2.9,1.4c-0.6,0.8-1,1.8-0.9,2.8s0.6,1.9,1.4,2.6c2.3,1.9,4.2,4.5,5.4,7.5c1.1,2.7,1.8,5.8,1.8,8.6
			c0,3.7-0.9,7.3-2.5,10.5c0,0,0,0.1-0.1,0.1c-0.8,1.7-1.9,3.2-3,4.6c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.4-0.7,0.8-1.1,1.2l0,0
			c-0.3,0.3-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.4-0.7,0.5c-0.1,0-0.1,0.1-0.2,0.1
			c-1.5,1.1-3.2,2.1-4.9,2.8c-0.7,0.3-1.4,0.6-2.1,0.8l0,0c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.5,0.1-0.8,0.2
			c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.5,0.1-0.7,0.1s-0.3,0.1-0.5,0.1s-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.4,0-0.6,0.1
			c-0.2,0-0.4,0-0.5,0c-0.3,0-0.7,0-1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0-1,0c-0.2,0-0.3,0-0.5,0s-0.4,0-0.7-0.1
			c-0.2,0-0.4,0-0.5-0.1c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1s-0.5-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.3-0.1
			c-0.3-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.2-1-0.3l0,0c-0.7-0.2-1.4-0.5-2.1-0.8c-1.8-0.7-3.4-1.7-4.9-2.8
			c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.4-0.7-0.7
			l-0.1-0.1l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.5-0.6-0.7l-0.1-0.1c-1.5-1.8-2.7-3.7-3.5-5.9
			c0,0,0,0,0-0.1c-1.2-2.8-1.8-5.9-1.8-9v-41.8h20.1c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8h-20.1v-32.8c2.5-1.3,5.3-2,8.1-2
			c1.8,0,3.6,0.3,5.3,0.8c4.1,1.4,8.3,2.2,12.2,2.2c7.5,0,14.3-2.6,19.2-7.5c4.7-4.6,7.3-10.8,7.3-17.2c0-5.7-2-11.1-5.9-15.7
			c-0.7-0.9-1.8-1.4-2.9-1.4c-0.9,0-1.8,0.3-2.4,0.9c-1.6,1.3-1.8,3.7-0.5,5.3c2.7,3.2,4.1,7,4.1,10.8c0,4.3-1.8,8.6-5.1,11.8
			c-3.6,3.5-8.4,5.3-13.9,5.3c-3.1,0-6.4-0.6-9.8-1.8h-0.1c-2.5-0.8-5-1.2-7.6-1.2c-2.8,0-5.5,0.5-8.1,1.4l0,0V90.7
			c0-3.1,0.6-6.2,1.8-9v-0.1c0.9-2.1,2.1-4.1,3.5-5.9c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.3,0.5-0.6,0.8-0.8
			c0.3-0.3,0.6-0.5,0.8-0.8c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1,0.2-0.1,0.3-0.2
			c0.3-0.2,0.6-0.4,0.9-0.6l0,0c1.3-0.8,2.6-1.6,4-2.2c0.7-0.3,1.4-0.5,2.1-0.8h0.1c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.1,0,0.2-0.1
			c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.2-0.1,0.4-0.1s0.5-0.1,0.7-0.1s0.3-0.1,0.5-0.1s0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0c0.3,0,0.7,0,1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.3,0,0.7,0,1,0c0.2,0,0.4,0,0.5,0
			c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1s0.5,0.1,0.7,0.1c0.1,0,0.2,0.1,0.4,0.1
			c0.3,0.1,0.5,0.1,0.8,0.2c0.1,0,0.2,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.3h0.1c0.7,0.2,1.4,0.5,2.1,0.8c1.4,0.6,2.7,1.3,4,2.2
			c0,0,0,0,0.1,0c0.3,0.2,0.6,0.4,0.8,0.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.3,0.6,0.5
			c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.3,0.6,0.5,0.8,0.8c0.2,0.2,0.5,0.5,0.7,0.7l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.7
			c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.5s0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.2,0.4
			c0.1,0.2,0.2,0.4,0.3,0.5s0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.2,0.5,0.3,0.8
			c0,0.1,0,0.1,0.1,0.2c0.7,1.9,1.2,3.9,1.4,6c-2.8,1.3-5.4,3-7.7,5.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l1.1,1.1l-1.1-1l-0.2,0.2
			c-0.2,0.2-0.4,0.5-0.8,0.9c-0.6,0.7-1.6,1.8-2.9,3c-2.2,2.2-5.6,5.4-9.1,8c-2.4,1.8-4.7,3.1-6.6,3.9c-1.1,0.5-2.1,0.8-3,0.8
			c-1,0.1-1.9,0.6-2.6,1.4c-0.6,0.8-0.9,1.8-0.8,2.8c0.2,1.9,1.8,3.4,3.8,3.4c0.1,0,0.3,0,0.4,0c1.6-0.2,3.3-0.6,5.1-1.4
			c1.5-0.6,3.1-1.5,4.8-2.5c2.7-1.7,5.6-3.9,8.6-6.6c4.5-4,8-7.9,8.8-8.8c2.9-2.5,6.4-4.3,10.1-5.2c0.4-0.1,0.9-0.2,1.3-0.3l0,0
			c0.4-0.1,0.8-0.1,1.2-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.3,0,0.4,0c0.4,0,0.8,0,1.2,0l0,0
			c6.3,0,12.3,2.5,16.7,7c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.2,0.4,0.5,0.6,0.7l0.1,0.1
			c3.4,4.2,5.3,9.4,5.3,14.8l0,0c0,0.5,0,1-0.1,1.5c0,0.2,0,0.5-0.1,0.7c0,0.3,0,0.5-0.1,0.8c0,0.3-0.1,0.6-0.1,0.9
			c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.3-0.1,0.7-0.2,1c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.4-0.2,0.7-0.3,1.1c0,0.1-0.1,0.2-0.1,0.3
			c-0.1,0.4-0.2,0.7-0.4,1.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.3,0.7-0.4,1c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2,0.3-0.3,0.6-0.5,1
			c-0.2,0.3-0.3,0.6-0.5,0.9c-0.3,0-0.5,0-0.8,0c-1.6,0-3.2,0.1-4.8,0.4c-2.1,0.3-3.5,2.3-3.2,4.3c0.3,1.9,1.9,3.2,3.8,3.2
			c0.2,0,0.4,0,0.6,0c1.2-0.2,2.4-0.3,3.6-0.3l0,0c0.3,0,0.6,0,0.8,0c1.5,0.1,2.9,0.2,4.3,0.5l0,0c1.7,0.4,3.4,1,4.9,1.7l0,0
			c1,0.5,2,1,2.9,1.6c0.1,0,0.1,0.1,0.2,0.1c0.9,0.6,1.7,1.2,2.4,1.9c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.3,0.7,0.7,1.1,1l0,0
			c0.3,0.3,0.5,0.6,0.8,0.8l0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.3,0.4
			c0.2,0.2,0.3,0.5,0.5,0.7c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.5,0.9c0,0,0,0,0,0.1c0.2,0.3,0.4,0.6,0.5,1l0,0
			c0.3,0.7,0.7,1.3,0.9,2l0,0c0.1,0.3,0.3,0.7,0.4,1c0,0,0,0,0,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.1,0.1,0.2
			c0.1,0.3,0.2,0.6,0.2,0.8c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.5,0.2,0.7c0,0.1,0.1,0.2,0.1,0.4s0.1,0.5,0.1,0.7
			c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.5,0.1,0.7s0,0.3,0.1,0.5c0,0.2,0,0.5,0.1,0.8c0,0.1,0,0.3,0,0.4c0,0.4,0,0.8,0,1.2
			c0,0.3,0,0.6,0,0.8l0,0c0,0.1,0,0.1,0,0.2c0,0.3,0,0.7-0.1,1c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.7-0.1,1.1l0,0c-0.3,2.4-1,4.7-2.1,6.8
			l0,0c-0.2,0.3-0.3,0.7-0.5,1c0,0,0,0,0,0.1c-0.2,0.3-0.4,0.6-0.5,0.9c0,0,0,0.1-0.1,0.1c-0.2,0.3-0.4,0.6-0.6,0.9
			c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.5-0.6,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.1,0.1-0.2,0.3-0.4,0.4
			c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.3,0.3-0.5,0.5c-0.4,0.4-0.9,0.9-1.4,1.3c-2.1-0.5-4.3-0.7-6.5-0.7c-7.1,0-14,2.5-19.5,6.9
			L263,184c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0.2l-0.2,0.3c-0.2,0.2-0.5,0.5-0.8,0.9c-0.7,0.7-1.8,1.8-3.3,3c-2.6,2.1-6.6,5.1-11.4,7
			c-3.3,1.3-6.6,2-9.8,2c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8c4.1,0,8.4-0.9,12.6-2.5c3.3-1.3,6.6-3.1,9.8-5.4
			c4.6-3.3,7.7-6.5,8.6-7.5c4.2-3.3,9.2-5,14.5-5c3.3,0,6.4,0.7,9.4,1.9l0,0c0.2,0.1,0.3,0.1,0.5,0.2c0.3,0.1,0.6,0.3,0.9,0.4h0.1
			l0,0c0.9,0.4,1.7,1,2.5,1.5c0.1,0.1,0.2,0.1,0.2,0.1l0.1,0.1c2.1,1.5,4,3.4,5.5,5.5c0.3,0.4,0.6,0.9,0.9,1.3
			c0.3,0.5,0.6,0.9,0.8,1.4c0,0.1,0.1,0.2,0.1,0.2c0.2,0.4,0.4,0.8,0.6,1.2c0,0.1,0,0.1,0.1,0.2c0.2,0.4,0.4,0.9,0.5,1.3v0.1
			c0.2,0.5,0.3,1,0.5,1.5c0,0,0,0,0,0.1c0.1,0.5,0.3,1,0.4,1.5c0,0.1,0,0.1,0,0.2c0.1,0.5,0.2,1,0.2,1.4c0,0.2,0,0.3,0.1,0.5
			c0,0.4,0.1,0.7,0.1,1.1c0,0.5,0.1,1.1,0.1,1.7C306.4,208.4,306.4,208.8,306.4,209.2z" />
								</g>
								<path d="M284.2,7.2l-10.7,2.6l5.3,21.7C249.2,11.1,213.9,0,177.8,0C79.8,0,0,79.8,0,177.8h11C11,85.8,85.8,11,177.8,11
		c34.7,0,68.6,10.9,96.8,31l-25.1,4.3l1.9,10.8l43.2-7.4L284.2,7.2z" />
								<path d="M344.6,177.8c0,92-74.8,166.8-166.8,166.8c-34.7,0-68.6-10.9-96.7-31l25-4.2l-1.8-10.8l-43.2,7.3l10.4,42.6
		l10.7-2.6l-5.3-21.8c29.6,20.4,64.9,31.5,101,31.5c98,0,177.8-79.8,177.8-177.8H344.6z" />
							</g>
						</svg>
					</div>
					<h2 className="HybridBrain__h2">
						スタジオ・アオでは
                  <br />"ハイブリッド人材"が
                  <br />ホームページを制作します
                </h2>
					<p className="HybridBrain__leadcopy">左脳と右脳の両方を行き来する、ハイブリッド思考のノウハウを持った人が、ロジックとデザインを平行してホームページを作ると、とてもバランスの良いものが出来上がります。逆に、ロジックとデザインを別々の人が作ると、意識ズレが発生する上、コストも増大します。スタジオ・アオでは、経験を積んだ、ハイブリッド思考の達人がホームページを制作します。</p>
				</div>
			</div>
		</div>
	</div>
)