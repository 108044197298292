import React, { useState, useEffect, useRef } from "react"
import './styles.scss'

export default ({ children, classAnim, ...props }) => {
	const target = useRef(null)
	const [classNames, setClassNames] = useState(["block", `${classAnim}`])
	useEffect(() => {
		const cb = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setClassNames(["block", `${classAnim}On`])
				} else {
					setClassNames(["block", `${classAnim}`])
				}
			})
		}
		const options = {
			root: null,             //ビューポートと交差
			rootMargin: "-20% 0px",  //上下内側に-10%の地点で処理を実行する
		}
		const io = new IntersectionObserver(cb, options)
		io.observe(target.current)
	}, [classAnim])

	return (
		<div ref={target} className={classNames.join(" ")}>{children}</div>
	)
}